var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"height",attrs:{"id":"content","role":"main"}},[_c('div',{staticClass:"container"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble"},[_c('li',{staticClass:"breadcrumb-item flex-shrink-0 flex-xl-shrink-1"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1)])]),(_vm.categories.length > 0)?_c('div',{staticClass:"row"},_vm._l((_vm.categories),function(category,rcdx){return _c('div',{key:rcdx,staticClass:"col-6 col-md-2-custome"},[_c('router-link',{attrs:{"to":{
            name: 'categoryProducts',
            params: {
              slug: category.slug,
            },
          }}},[_c('div',{staticClass:"box"},[_c('span',{staticClass:"box-txt"},[_c('span',{attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" "+_vm._s(category.name)+" ")])])])],1)}),0):_vm._e()]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"container overflow-hidden"},[_vm._m(0),_c('products',{attrs:{"products":_vm.products}})],1),_c('InfiniteLoading',{on:{"infinite":_vm.allProducts}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between border-bottom border-color-1 flex-lg-nowrap flex-wrap border-md-down-top-0 border-md-down-bottom-0 mt-2"},[_c('h3',{staticClass:"section-title section-title__full mb-0 pb-2 font-size-22"},[_vm._v(" All products ")])])
}]

export { render, staticRenderFns }